<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.5396 12.2749C23.5396 11.4239 23.4632 10.6058 23.3214 9.82031H12.0195V14.4676H18.4777C18.1941 15.9621 17.3432 17.2276 16.0668 18.0785V21.1003H19.9614C22.2305 19.0058 23.5396 15.9294 23.5396 12.2749Z"
      fill="#4285F4"
    />
    <path
      d="M12.0213 23.9982C15.2614 23.9982 17.9777 22.9291 19.9632 21.0963L16.0686 18.0745C14.9995 18.7945 13.6359 19.2309 12.0213 19.2309C8.90134 19.2309 6.25043 17.1254 5.30133 14.2891H1.30859V17.3872C3.28314 21.3036 7.33043 23.9982 12.0213 23.9982Z"
      fill="#34A853"
    />
    <path
      d="M5.29955 14.2832C5.05955 13.5632 4.91773 12.7995 4.91773 12.0032C4.91773 11.2068 5.05955 10.4432 5.29955 9.72318V6.625H1.30681C0.488624 8.23955 0.0195312 10.0614 0.0195312 12.0032C0.0195312 13.945 0.488624 15.7668 1.30681 17.3814L4.41591 14.9595L5.29955 14.2832Z"
      fill="#FBBC05"
    />
    <path
      d="M12.0213 4.77818C13.7886 4.77818 15.3595 5.38909 16.6141 6.56727L20.0505 3.13091C17.9668 1.18909 15.2614 0 12.0213 0C7.33043 0 3.28314 2.69455 1.30859 6.62182L5.30133 9.72C6.25043 6.88363 8.90134 4.77818 12.0213 4.77818Z"
      fill="#EA4335"
    />
  </svg>
</template>
